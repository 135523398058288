import { MessageReceiver } from './MessageReceiver';
import XhrRequestError from './XhrRequestError';

class MessageCourier {
  private readonly defaultErrorMessage = 'Hubo un error inesperado. Por favor, intenta nuevamente más tarde';

  private receivers: MessageReceiver[];

  constructor() {
    this.receivers = [];
  }

  subscribe(aReceiver: MessageReceiver) {
    // this.assertIsNotSubscribed(aReceiver);
    if (!this.isSubscribed(aReceiver)) {
      this.receivers.push(aReceiver);
    }
  }

  unsubscribe(aReceiver: MessageReceiver) {
    this.receivers = this.receivers.filter((receiver) => receiver !== aReceiver);
  }

  messageError(error: unknown) {
    const message = this.getErrorMessage(error);
    this.notifyError(message, error);
  }

  messageSuccess(message: string) {
    this.forEachReceiverDo((receiver) => receiver.receiveSuccess(message));
  }

  private notifyError(message: string, error: unknown) {
    this.forEachReceiverDo((receiver) => receiver.receiveError(message, error));
  }

  private forEachReceiverDo(aClosure: (receiver: MessageReceiver) => unknown) {
    this.receivers.forEach(aClosure);
  }

  private getErrorMessage(e: unknown) {
    if (typeof e === 'string') {
      return e;
    }
    if (typeof e === 'object') {
      if (e instanceof XhrRequestError) {
        const errorMessage = e.getApiErrorMessage();
        if (errorMessage) {
          return errorMessage;
        }
      }
      if (e instanceof Error) {
        console.log('error :>> ', e);
        return `${e.message} (${e.name})`;
      }
    }
    return this.defaultErrorMessage;
  }

  private assertIsNotSubscribed(aReceiver: MessageReceiver) {
    if (this.isSubscribed(aReceiver)) {
      throw new Error('The receiver is already subscribed');
    }
  }

  private isSubscribed(aReceiver: MessageReceiver) {
    return this.receivers.includes(aReceiver);
  }
}

export default MessageCourier;
