import React, { useState } from 'react';
import './Carousel.css'; // Asegúrate de crear este archivo CSS
import { AdImages } from '../../common/configurable';

export const Carousel = ({
  images,
  autoPlayInterval = 3000,
}: {
  images: AdImages[];
  autoPlayInterval?: number;
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, autoPlayInterval);

    return () => clearInterval(interval); // Limpia el intervalo cuando el componente se desmonta
  }, [images.length, autoPlayInterval]);

  return (
    <div className="carousel">
      <div className="carousel-inner" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {images.map((image, index) => (
          <div className="carousel-item " key={index}>
            <img
              src={image.imagePath}
              alt={`Slide ${index}`}
              className="clickable"
              onClick={() => {
                if (image.redirectTo) {
                  window.open(image.redirectTo, '_blank');
                }
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
