import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import SystemClient from './system/SystemClient';
import Environment from './lib/Environment';
import MessageCourier from './lib/MessageCourier';
import AppInRouterContext from './AppInRouterContext';
import { ThemeProvider } from '@fluentui/react/lib/Theme';
import { initializeIcons } from '@fluentui/react/lib/Icons';

const environment = new Environment();
const system = SystemClient.start(environment);
const appMessageCourier = new MessageCourier();

initializeIcons();

function App() {
  return (
    <React.Fragment>
      {system && (
        <ThemeProvider style={{ height: '100%', overflow: 'hidden' }}>
          <BrowserRouter basename={environment.getLocalBasePath()}>
            <AppInRouterContext system={system} environment={environment} courier={appMessageCourier} />
          </BrowserRouter>
        </ThemeProvider>
      )}
    </React.Fragment>
  );
}

export default App;
