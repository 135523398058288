import React from 'react';
import {
  Pivot,
  PivotItem,
  Text,
  IconButton,
  Icon,
  DefaultButton,
  ChoiceGroup,
  IChoiceGroupOption,
} from '@fluentui/react';
import ClubImageCircle from '../Common/ClubImageCircle';
import { grilla, ModeloGrilla, Zona, Fase } from '../../system/SystemClient';
import { PartidoFixture } from '../Common/PartidoFixture';
import { genkey } from '../../common/constants';
import { ZonaFixture } from '../Common/ZonaFixture';
import { TorneoLlaves } from './TorneoLlaves';

export interface Instancia {
  ronda: string;
  descripcion: string;
  llave_principal: string;
  partidos: string[];
}

export interface InstanciaPorFase {
  fase: string;
  instancias: Instancia[];
}

export const TorneoInstanciaFixture = ({
  zona,
  fase,
  instanciasPorFase,
}: {
  zona: Zona;
  fase: Fase;
  instanciasPorFase: InstanciaPorFase[];
}) => {
  return (
    <div>
      {instanciasPorFase
        .find((ixf) => ixf.fase === fase.nombre)
        ?.instancias.map((instancia, instanciaIdx) => {
          return (
            <div>
              <div className="ms-Grid-col ms-sm12 ">
                <div className="ms-Grid-row" style={{ marginTop: 15, marginBottom: 15 }}>
                  <div className="ms-Grid-col ms-sm12 instancia-fecha">
                    <div className="ms-Grid-row" style={{ marginTop: 10, marginBottom: 10 }}>
                      <div className="ms-Grid-col ms-sm12">
                        <Text variant="large" className="fecha-label">
                          {instancia.llave_principal && (
                            <Icon
                              title="Llave principal"
                              className="mr-1"
                              style={{ fontWeight: 600 }}
                              iconName="Trophy2"
                            ></Icon>
                          )}
                          <Text
                            className="fecha-label"
                            variant={instancia.llave_principal ? 'large' : 'medium'}
                            style={{ fontWeight: 500 }}
                          >
                            {instancia.descripcion}
                          </Text>
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
                {zona.partidos
                  .filter((p, idxP) => instancia.partidos.some((ip) => +ip === idxP + 1))
                  .map((partido, iPartido) => {
                    return <PartidoFixture partido={partido} />;
                  })}
              </div>
            </div>
          );
        })}
    </div>
  );
};
