import axios, { AxiosError } from 'axios';

const unknownErrorName = 'ErrorUnk';
const unexpectedErrorName = 'ErrorInesperado';
const genericErrorName = 'ErrorConsulta';
const cancelledErrorName = 'Cancelado';

type IndexableObject = { [index: string]: any };

function isIndexableObject(value: unknown): value is IndexableObject {
  return typeof value === 'object' && !!value && !Array.isArray(value);
}

class XhrRequestError extends Error {
  readonly name: string;

  private static readonly statusCodeDefault = -1;
  private static readonly requestCodeDefault = -1;

  constructor(name: string, message: string, private originalError?: AxiosError) {
    super(message);
    this.name = name;
  }

  static fromUnknown(error: unknown) {
    if (typeof error === 'string') {
      return this.errorWhenString(error);
    }
    if (typeof error === 'object') {
      return this.errorWhenObject(error);
    }
    return this.unknownError();
  }

  private static errorWhenString(error: string) {
    return new this(unexpectedErrorName, error);
  }

  private static errorWhenObject(error: any) {
    if (axios.isAxiosError(error)) {
      const message = `${error.message} (${error.name} [${error.code}]) `;
      if (axios.isCancel(error)) {
        return new this(cancelledErrorName, message);
      }
      return new this(genericErrorName, message, error);
    }

    if (error instanceof Error) {
      return new this(unexpectedErrorName, error.message);
    }

    return this.unknownError();
  }

  private static unknownError() {
    return new this(unknownErrorName, 'Hubo un error inesperado. Por favor, contacta a tu administrador');
  }

  getApiErrorMessage(): string {
    const response = this.responseData();
    if (isIndexableObject(response) && 'error' in response && typeof response.error === 'string') {
      return response.error;
    }
    return '';
  }

  apiErrorCodeIs(anErrorCode: string): boolean {
    return this.getApiErrorStringCode() === anErrorCode;
  }

  getApiErrorCode(): number {
    const response = this.responseData();
    if (isIndexableObject(response) && 'statusCode' in response && typeof response.statusCode === 'number') {
      return response.statusCode!;
    }
    return XhrRequestError.statusCodeDefault;
  }

  isCancel(): boolean {
    return this.name === cancelledErrorName;
  }

  isAuthenticationRequiredError(): boolean {
    return this.getHttpResponseCode() === 401;
  }

  isUnauthorized(): boolean {
    return this.getHttpResponseCode() === 405;
  }

  isNotAllowed(): boolean {
    return this.getHttpResponseCode() === 410;
  }

  private getHttpResponseCode(): number {
    return this.originalError?.response?.status || XhrRequestError.requestCodeDefault;
  }

  responseData(): unknown {
    return this.originalError?.response?.data;
  }

  private getApiErrorStringCode(): string {
    const response = this.responseData();
    if (isIndexableObject(response) && 'code' in response && typeof response.code === 'string') {
      return response.code;
    }
    return '';
  }
}

export default XhrRequestError;
