export const APP_VERSION = '0.0.0';

export const genkey = () => {
  let caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let longitud = 12;
  let resultado = '';
  for (let i = 0; i < longitud; i++) {
    resultado += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
  }
  return resultado;
};

export const PARTIDOS_EXTRA_ZONA_NAME = 'partidos extras';

function toBase64(str: string) {
  return btoa(unescape(encodeURIComponent(str)));
}

// Función para convertir una Base64 a cadena
function fromBase64(str: string) {
  return decodeURIComponent(escape(atob(str)));
}

// Función para cifrar el string usando XOR
export function hash(mensaje: string, clave: string) {
  let resultado = '';
  for (let i = 0; i < mensaje.length; i++) {
    resultado += String.fromCharCode(mensaje.charCodeAt(i) ^ clave.charCodeAt(i % clave.length));
  }
  return toBase64(resultado);
}

// Función para descifrar el string
export function unhash(mensajeCifrado: string, clave: string) {
  let mensajeXor = fromBase64(mensajeCifrado);
  let resultado = '';
  for (let i = 0; i < mensajeXor.length; i++) {
    resultado += String.fromCharCode(mensajeXor.charCodeAt(i) ^ clave.charCodeAt(i % clave.length));
  }
  return resultado;
}
