import React from 'react';
import { IconButton } from '@fluentui/react/lib/Button';
import {
  DefaultPalette,
  Stack,
  IStackStyles,
  IStackTokens,
  IStackItemStyles,
  Dropdown,
  IDropdownOption,
  DropdownMenuItemType,
} from '@fluentui/react';
import { IContextualMenuItem, ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { Image, ImageFit } from '@fluentui/react/lib/Image';
import { useNavigate } from 'react-router-dom';
import SystemClient, { ContextMenu } from '../../../system/SystemClient';
import './layoutStyles.css';
import MessageCourier from '../../../lib/MessageCourier';
import {
  AppContext,
  CRITERIO_CATEGORIA,
  CRITERIO_FEDERACION,
  CRITERIO_RAMA,
  CRITERIO_TEMPORADA,
  MenuFilters,
} from '../../../AppInRouterContext';
import useAsyncDataCourier from '../../../common/custom-hooks/useAsyncDataCourier';
import { AdImages } from '../../../common/configurable';
// import { headerColor, headerImages, LOGO_HEADER_PATH } from '../../../common/configurable';

const stackItemRoorStyles = {
  alignItems: 'center',
  display: 'flex',
  height: 30,
};

const stackItemStyles: IStackItemStyles = {
  root: {
    ...stackItemRoorStyles,
    justifyContent: 'start',
  },
};

const stackItemJustifyEndStyles: IStackItemStyles = {
  root: {
    ...stackItemRoorStyles,
    justifyContent: 'end',
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 5,
  // padding: 10,
};

interface IHeaderProps {
  onMenuButtonClick: () => void;
  appContext: AppContext;
  headerColor?: string;
  logoPath?: string;
  headerImages?: AdImages[];
}

const signOutKey = 'signOut';
const myPanelKey = 'myPanel';
const openNotificationsKey = 'openNotifications';
const openPendingActivities = 'openPendingActivities';

const Header: React.FC<IHeaderProps> = (props) => {
  const personaRef = React.useRef(null);
  const [showContextualMenu, setShowContextualMenu] = React.useState(false);
  const [showNotificationMenu, setShowNotificationMenu] = React.useState(false);

  const stackStyles: IStackStyles = {
    root: {
      // background: DefaultPalette.themeTertiary,
      background: props.headerColor,
      width: '100%',
      height: '6%',
      padding: '0.5rem',
      maxWidth: 1380,
    },
  };

  const onHideContextualMenu = () => {
    setShowContextualMenu(false);
    setShowNotificationMenu(false);
  };

  const menuItems: IContextualMenuItem[] = React.useMemo(
    () => [
      { key: myPanelKey, iconProps: { iconName: 'AccountManagement' }, text: 'Mi Perfil' },
      { key: signOutKey, iconProps: { iconName: 'SignOut' }, text: 'Cerrar sesión' },
    ],
    []
  );

  const onClickItem = (item: IContextualMenuItem | undefined) => {};

  return (
    <Stack horizontal styles={stackStyles} tokens={stackTokens}>
      <React.Fragment>
        <Stack.Item grow={1} styles={stackItemStyles}>
          <Image
            imageFit={ImageFit.contain}
            height={42}
            src={props.logoPath}
            alt='Example of the image fit value "centerContain" on an image smaller than the frame.'
          />
        </Stack.Item>
        <Stack.Item grow={1} styles={stackItemStyles}></Stack.Item>
        <Stack.Item grow={3} styles={stackItemJustifyEndStyles}>
          <ContextualMenu
            items={menuItems}
            hidden={!showContextualMenu}
            target={personaRef}
            onItemClick={(e, item) => onClickItem(item)}
            onDismiss={onHideContextualMenu}
          />
        </Stack.Item>
        <Stack.Item grow={1} styles={stackItemJustifyEndStyles}>
          {props.headerImages &&
            props.headerImages.map((image, i) => {
              return (
                <Image
                  key={i}
                  imageFit={ImageFit.contain}
                  height={42}
                  src={image.imagePath}
                  onClick={() => {
                    window.open(image.redirectTo, '_blank');
                  }}
                  className="clickable"
                />
              );
            })}
        </Stack.Item>
      </React.Fragment>
    </Stack>
  );
};

export default Header;
