import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Environment from './Environment';
import XhrRequestError from './XhrRequestError';
import XhrRequestCache from './XhrRequestCache';
import { getDeviceData } from './DeviceDetector';
import XhrErrorListener from './XhrErrorListener';
import { CryptoJSAesJson } from './CryptoAes/cryptojs-aes-format';

const progressKey = 'progress';

interface IProgressEvent {
  loaded: number;
  total: number;
  type: string;
}

export type UploadTracker = (loaded: number, total: number) => unknown;

interface IUnauthConfig {
  uploadTracker?: UploadTracker;
  abortController?: AbortController;
}

class XhrRequestHandler {
  private listeners: XhrErrorListener[];

  constructor(private environment: Environment, private cache: XhrRequestCache) {
    this.listeners = [];
  }

  clearCache() {
    this.cache.clear();
  }

  subscribe(aListener: XhrErrorListener) {
    this.assertIsNotSubscribed(aListener);
    this.listeners.push(aListener);
  }

  private assertIsNotSubscribed(aListener: XhrErrorListener) {
    if (this.isSubscribed(aListener)) {
      throw new Error('The listener is already subscribed');
    }
  }

  private isSubscribed(aListener: XhrErrorListener) {
    return this.listeners.includes(aListener);
  }

  unsubscribe(aListener: XhrErrorListener) {
    this.listeners = this.listeners.filter((listener) => listener !== aListener);
  }

  shouldDecrypt<T>(response: AxiosResponse<any, any>) {
    const responseData = response.data;
    if (response.headers['x-content-encrypted'] === 'true') {
      let password = this.environment.passphrase();
      const ret = CryptoJSAesJson.decrypt(JSON.stringify(responseData), password);
      return JSON.parse(ret) as T;
    } else {
      return responseData as T;
    }
  }

  /**
   * @throws {XhrRequestError}
   */
  async get<T>(url: string): Promise<T> {
    return this.cache.getValue(url, (url) => {
      return this.doThrowingRequestError<T>(url, async (fullUrl, config) => {
        const response = await axios.get(fullUrl, config);
        return this.shouldDecrypt(response);
      });
    });
  }

  /**
   * @throws {XhrRequestError}
   */
  async post<T = unknown, S = unknown>(url: string, data?: S): Promise<T> {
    const requestBody = data === undefined ? {} : data;
    return this.doThrowingRequestError(url, async (fullUrl, config) => {
      const response = await axios.post(fullUrl, requestBody, config);
      return this.shouldDecrypt(response);
      // (await axios.post<T>(fullUrl, requestBody, config)).data
    });
  }

  /**
   * @throws {XhrRequestError}
   */
  async postWithFormData<T = unknown, S = unknown>(url: string, data?: FormData): Promise<T> {
    const requestBody = data === undefined ? {} : data;
    return this.doThrowingRequestError(url, async (fullUrl, config) => {
      if (config && config.headers) {
        config.headers['Content-Type'] = 'multipart/form-data';
      }

      const response = await axios.post(fullUrl, requestBody, config);
      return this.shouldDecrypt(response);
      // return (await axios.post<T>(fullUrl, requestBody, config)).data;
    });
  }

  /**
   * @throws {XhrRequestError}
   */
  async put<T, S>(url: string, data: S): Promise<T> {
    return this.doThrowingRequestError(url, async (fullUrl, config) => {
      const response = await axios.put(fullUrl, data, config);
      return this.shouldDecrypt(response);

      // (await axios.put<T>(fullUrl, data, config)).data
    });
  }

  /**
   * @throws {XhrRequestError}
   */
  async patch<T = unknown, S = unknown>(url: string, data: S): Promise<T> {
    return this.doThrowingRequestError(url, async (fullUrl, config) => {
      const response = await axios.patch(fullUrl, data, config);
      return this.shouldDecrypt(response);

      // (await axios.patch<T>(fullUrl, data, config)).data
    });
  }

  /**
   * @throws {XhrRequestError}
   */
  async unauthenticatedPutForUpload<T, S>(
    url: string,
    data: S,
    { uploadTracker, abortController }: IUnauthConfig
  ): Promise<T> {
    return this.doThrowingRequestError(url, async (fullUrl) => {
      const response = await axios.put(fullUrl, data, {
        onUploadProgress: (event) => {
          // if (event.type !== progressKey || !uploadTracker) return;
          // uploadTracker(event.loaded, event.total);
        },
        signal: abortController && abortController.signal,
      });
      return this.shouldDecrypt(response);

      //   (
      //   await axios.put<T>(fullUrl, data, {
      //     onUploadProgress: (event) => {
      //       // if (event.type !== progressKey || !uploadTracker) return;
      //       // uploadTracker(event.loaded, event.total);
      //     },
      //     signal: abortController && abortController.signal,
      //   })
      // ).data
    });
  }

  /**
   * @throws {XhrRequestError}
   */
  delete<T = unknown>(url: string): Promise<T> {
    return this.doThrowingRequestError(url, async (fullUrl, config) => {
      const response = await axios.delete(fullUrl, config);
      return this.shouldDecrypt(response);

      // (await axios.delete<T>(fullUrl, config)).data
    });
  }

  navigateTo(url: string) {
    return this.openUrl(url, false);
  }

  open(url: string) {
    return this.openUrl(url, true);
  }

  /**
   * @throws {XhrRequestError}
   */
  private async doThrowingRequestError<T>(
    url: string,
    closure: (requestUrl: string, config?: AxiosRequestConfig) => Promise<T>
  ): Promise<T> {
    try {
      const requestUrl = this.requestUrl(url);
      const defaultConfig: AxiosRequestConfig = { headers: this.defaultHeaders(), withCredentials: true };
      return await closure(requestUrl, defaultConfig);
    } catch (error) {
      const processedError = XhrRequestError.fromUnknown(error);
      console.log('processedError > ', processedError);
      if (processedError.isAuthenticationRequiredError()) {
        const returnTo = `?returnTo=${window.location.href}`;
        const data = processedError.responseData() as { redirect_to: string };
        const url = this.requestUrl(data.redirect_to) + returnTo;
        console.log('url > ', url);
        this.navigateTo(url);
      }

      if (processedError.isUnauthorized()) {
        window.location.href = '/permisos-insuficientes';
      }

      // if (processedError.isNotAllowed()) {
      //   // sin datos disponibles
      //   console.log('ver que hacer con este error');
      // }

      this.listeners.forEach((listener) => listener.receiveError(processedError));
      throw processedError;
    }
  }

  requestUrl(url: string) {
    return this.isRelative(url) ? this.environment.apiUrl(url) : url;
  }

  private isRelative(url: string) {
    return !url.startsWith('http');
  }

  private defaultHeaders() {
    const headers: { [index: string]: string } = {};
    headers['X-Device-Info'] = getDeviceData();

    return headers;
  }

  private openUrl(url: string, newPage: boolean) {
    const destination = encodeURI(this.requestUrl(url));
    if (newPage) {
      window.open(destination, '_blank');
    } else {
      window.location.assign(destination);
    }
  }
}

export default XhrRequestHandler;
export { XhrRequestError };

export interface QueryParam {
  name: string;
  value?: string | boolean | number;
}

export const getQueryParamsExpression = (params?: QueryParam[]): string => {
  if (!params) return '';

  let query = '';
  let separator = '?';
  params.forEach((param) => {
    if (
      param.name.length > 0 &&
      ((typeof param.value == 'string' && param.value.length > 0) ||
        typeof param.value == 'boolean' ||
        typeof param.value == 'number')
    ) {
      if (typeof param.value == 'boolean') param.value = param.value ? '1' : '0';
      query = `${query}${separator}${param.name}=${encodeURIComponent(param.value)}`;
      separator = '&';
    }
  });
  return query;
};

export interface StatusResponseJSON<T> {
  data: T;
  statusCode: number;
}
