import React from 'react';
import { Pivot, PivotItem, Text, IconButton, Icon } from '@fluentui/react';
import ClubImageCircle from '../Common/ClubImageCircle';
import { todosContraTodos, Torneo, Zona, Partido, ModeloGrilla } from '../../system/SystemClient';
import { genkey } from '../../common/constants';
import { Bracket, IRoundProps, Seed, SeedItem, IRenderSeedProps } from 'react-brackets';

const SEPARATOR = '*-*';

const getTeamNameForReactBrackets = (partido: Partido, local: boolean) => {
  const twoTeams = !!partido.nombreLocal && !!partido.nombreVisitante;

  const golesLocal = partido.golesLocal && twoTeams ? partido.golesLocal : 'null';
  const golesVisitante = partido.golesVisitante && twoTeams ? partido.golesVisitante : 'null';

  if (local)
    return partido.nombreLocal
      ? partido.escudoImagePathLocal! + SEPARATOR + partido.nombreLocal + SEPARATOR + golesLocal
      : '';
  if (!local)
    return partido.nombreVisitante
      ? partido.escudoImagePathVisitante! + SEPARATOR + partido.nombreVisitante + SEPARATOR + golesVisitante
      : '';
};

export const TorneoLlaves = ({ zona, modeloGrilla }: { zona: Zona; modeloGrilla: ModeloGrilla[] }) => {
  const estaDentroDeLaRonda = (rondaNro: number, nroPartido: number) => {
    return modeloGrilla?.some(
      (modelo) =>
        +modelo.mtg_ronda === +rondaNro &&
        +nroPartido === +modelo.mtg_partido_numero &&
        modelo.mtg_llave_principal
    );
  };

  const rounds: IRoundProps[] = React.useMemo(() => {
    const partidos = zona.partidos.filter((p) => !p.extra) as Partido[];

    const ret: IRoundProps[] = [];

    if (modeloGrilla) {
      const rondasInfoRepetidas = modeloGrilla
        .filter((modelo) => modelo.mtg_llave_principal)
        .map((mtg) => {
          return { ronda_desc: mtg.mtg_descripcion, ronda_nro: +mtg.mtg_ronda };
        });

      const rondasInfo: { ronda_desc: string; ronda_nro: number }[] = [];
      rondasInfoRepetidas.forEach((ri) => {
        const i = rondasInfo.findIndex((_ri) => ri.ronda_nro === _ri.ronda_nro);
        if (i === -1) rondasInfo.push(ri);
      });

      rondasInfo.forEach((rondaInfo) => {
        const ronda: IRoundProps = {
          title: rondaInfo.ronda_desc,
          seeds: partidos
            .filter((partido, i) => estaDentroDeLaRonda(+rondaInfo.ronda_nro, i + 1))
            .map((partido, i) => {
              return {
                id: i,
                date: '',
                teams: [
                  {
                    name: getTeamNameForReactBrackets(partido, true),
                  },
                  {
                    name: getTeamNameForReactBrackets(partido, false),
                  },
                ],
              };
            }),
        };
        ret.push(ronda);
      });
    }

    return ret;
  }, []);

  return (
    <div>
      <div className="ms-Grid-col ms-sm12">
        <div className="ms-hiddenMdUp mt-1 text-center">
          <Text variant="small">Desliza a la derecha para ver más...</Text>
        </div>
        <Bracket
          rounds={rounds}
          renderSeedComponent={({ seed, breakpoint, roundIndex, seedIndex }: IRenderSeedProps) => {
            const equipoLocalInfo = seed.teams[0]?.name?.split(SEPARATOR);
            const equipoVisitanteInfo = seed.teams[1]?.name?.split(SEPARATOR);
            return (
              <Seed mobileBreakpoint={breakpoint} className="test-clase react-swipeable-view-container">
                <SeedItem className="test-seed-item react-swipeable-view-container">
                  <SeedTeamCustomGrilla equipoInfo={equipoLocalInfo}></SeedTeamCustomGrilla>
                  <SeedTeamCustomGrilla equipoInfo={equipoVisitanteInfo}></SeedTeamCustomGrilla>
                </SeedItem>
              </Seed>
            );
          }}
        />
      </div>
    </div>
  );
};

export const SeedTeamCustomGrilla = (props: { equipoInfo: string[] | undefined }) => {
  return (
    <div className="ms-Grid-row" style={{ paddingTop: 5, paddingBottom: 5 }}>
      {props.equipoInfo && props.equipoInfo[1] && (
        <div className="ms-Grid-col ms-sm3" style={{ paddingRight: 0 }}>
          <ClubImageCircle
            escudo={props.equipoInfo[0]}
            width={25}
            height={25}
            useMarginForGrid
          ></ClubImageCircle>
        </div>
      )}
      {props.equipoInfo && props.equipoInfo[1] ? (
        <div
          className="ms-Grid-col ms-sm7 text-overflow"
          style={{ marginTop: 3, paddingLeft: 0, textAlign: 'left' }}
        >
          <Text style={{ color: 'white' }} title={props.equipoInfo[1]} variant="medium">
            {props.equipoInfo[1]}
          </Text>
        </div>
      ) : (
        <>
          <div className="ms-Grid-col ms-sm3" style={{ paddingRight: 0 }}></div>

          <div
            className="ms-Grid-col ms-sm7 text-overflow"
            style={{ marginTop: 3, paddingLeft: 0, textAlign: 'center' }}
          >
            <Text
              style={{
                color: 'white',
              }}
              variant="medium"
            >
              Sin equipo asignado
            </Text>
          </div>
        </>
      )}
      {props.equipoInfo && props.equipoInfo[2] ? (
        <div
          className="ms-Grid-col ms-sm2 text-overflow"
          style={{ marginTop: 3, paddingLeft: 0, textAlign: 'center', fontWeight: 600 }}
        >
          {props.equipoInfo[2] != 'null' ? props.equipoInfo[2] : '-'}
        </div>
      ) : (
        <div
          className="ms-Grid-col ms-sm2 text-overflow"
          style={{ marginTop: 3, paddingLeft: 0, textAlign: 'center', fontWeight: 600 }}
        >
          -
        </div>
      )}
    </div>
  );
};
