import React from 'react';
import { Pivot, PivotItem, Text, Icon, DefaultButton } from '@fluentui/react';
import { DateTime } from 'luxon';
import TorneoTabFixture from './TorneoTabFixture';
import TorneoTabGoleadores from './TorneoTabGoleadores';
import TorneoTabTablaPosiciones from './TorneoTabTablaPosiciones';
import { ModeloGrilla, todosContraTodos, Torneo } from '../../system/SystemClient';
import { genkey, PARTIDOS_EXTRA_ZONA_NAME, unhash } from '../../common/constants';
import { ZonaFixture } from '../Common/ZonaFixture';
import { TorneoNotFound } from '../Common/TorneoNotFound';
import {
  AppContext,
  CRITERIO_CATEGORIA,
  CRITERIO_FEDERACION,
  CRITERIO_RAMA,
  CRITERIO_TEMPORADA,
  MenuFilters,
} from '../../AppInRouterContext';
import {
  DefaultPalette,
  Stack,
  IStackStyles,
  IStackTokens,
  IStackItemStyles,
  Dropdown,
  IDropdownOption,
  DropdownMenuItemType,
} from '@fluentui/react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
interface ITorneoDetailViewProps {
  appContext: AppContext;
  onChangeFilters: (criterio: string, value: string) => void;
  menuFilters: MenuFilters;

  categorias: IDropdownOption<any>[];
  temporadas: IDropdownOption<any>[];
  federaciones: IDropdownOption<any>[];
  ramas: IDropdownOption<any>[];
}

const TorneoDetailView = (props: ITorneoDetailViewProps) => {
  let { id } = useParams();

  const [searchParams] = useSearchParams();
  // Obtener un valor de un query param específico
  const byLink = searchParams.get('direct'); // ejemplo: ?myParam=valor

  const [torneo, setTorneo] = React.useState<Torneo | undefined>(undefined);
  const [lastUpdate, setLastUpdate] = React.useState<any>(new Date());
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [showTorneoNotFoundDialog, setShowTorneoNotFoundDialog] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getTorneo = async () => {
      try {
        if (id) {
          let password = props.appContext.system.getEnvironment().passphrase();

          const idUnhashed = unhash(id, password);

          props.appContext.changeBlockUI(true);
          const ret = await props.appContext.system.getTorneo(idUnhashed);
          if (ret !== null) {
            setTorneo(ret);
            setLastUpdate(new Date());
          } else {
            setShowTorneoNotFoundDialog(true);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        props.appContext.changeBlockUI(false);
      }
    };
    getTorneo();

    const algunPartidoEnVivo = torneo?.fases.some((fase) =>
      fase.zonas.some((zona) => zona.partidos.some((partido) => partido.playing))
    );

    let intervaloTiempo = 300000; // 5 minutos

    if (algunPartidoEnVivo) {
      intervaloTiempo = 60000; // 1 minuto
    }

    const intervalo = setInterval(getTorneo, intervaloTiempo);

    return () => clearInterval(intervalo);
  }, [refresh]);

  const navigate = useNavigate();


  return (
    <>
      {torneo && (
        <>
          <div className="ms-Grid mt-2" dir="ltr" style={{ marginBottom: 10 }}></div>
          <div className="ms-Grid-col ms-sm12">
            <div className="ms-Grid-row " style={{ display: 'flex' }}>
              <div className="ms-Grid-col ms-sm12">
                <div className="ms-Grid bg-white-h100 ms-depth-4" dir="ltr">
                  <>
                    <div className="ms-Grid-row padding-1">
                      <div className="ms-Grid-col ms-sm12 text-center"></div>
                      <div className="ms-Grid-col ms-md8 ms-sm12">
                        <Text className="nombre-header-torneo d-block">
                          {torneo.detalle.nombreTorneo} |{' '}
                          <span style={{ fontWeight: 400 }}>
                            {torneo.detalle.categoria.categoriaNombre} -{' '}
                            {torneo.detalle.division.divisionNombre
                              ? torneo.detalle.division.divisionNombre + ' - '
                              : ''}
                            {torneo.detalle.rama === 'M' ? 'Masculino ' : 'Femenino '}
                          </span>
                        </Text>
                        <Text variant="small" className="d-block">
                          {torneo.detalle.federacion.federacionNombre}
                        </Text>
                      </div>
                      <div className="ms-Grid-col ms-md4 ms-sm12">
                        <div className="ms-Grid-row mt-1">
                          {!byLink && (
                            <div className="ms-Grid-col ms-sm12">
                              <DefaultButton className="float-right" onClick={() => navigate('/')}>
                                Volver al menú
                              </DefaultButton>
                            </div>
                          )}
                          <div className="ms-Grid-col ms-sm12 mt-1">
                            <Text
                              variant="small"
                              className="link-styles clickable float-right"
                              onClick={() => {
                                setRefresh(!refresh);
                              }}
                            >
                              Actualizado: {DateTime.fromJSDate(lastUpdate).toFormat('dd/LL/yyyy HH:mm')}
                              <Icon className="ml-1 " iconName="Refresh"></Icon>
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ms-Grid-col ms-sm12"
                      style={{ borderTop: '1px solid rgb(232, 232, 232)' }}
                    ></div>
                    <div className="ms-Grid-col ms-sm12" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="ms-Grid-row padding-row-custom">
                        <Pivot aria-label="" className="text-center customFocusZone">
                          <PivotItem
                            headerText="Fixture"
                            itemKey={'1'}
                            onRenderItemLink={(itemProps) => {
                              return (
                                <div>
                                  <span>{itemProps?.headerText}</span>
                                </div>
                              );
                            }}
                          >
                            <TorneoTabFixture torneo={torneo}></TorneoTabFixture>
                          </PivotItem>
                          {torneo.fases.filter((f) => todosContraTodos(f)).length > 0 && (
                            <PivotItem
                              headerText="Tabla posiciones"
                              itemKey={'2'}
                              onRenderItemLink={(itemProps) => {
                                return (
                                  <div>
                                    <span>{itemProps?.headerText}</span>
                                  </div>
                                );
                              }}
                            >
                              <Pivot aria-label="" className="text-left">
                                {torneo.fases
                                  .filter((f) => todosContraTodos(f))
                                  .map((fase, iFase) => {
                                    return (
                                      <PivotItem headerText={fase.nombre} key={genkey()}>
                                        {fase.zonas
                                          .filter((zona) => zona.nombre !== PARTIDOS_EXTRA_ZONA_NAME)
                                          .map((zona) => {
                                            return (
                                              <div key={genkey()}>
                                                {fase.zonas.filter(
                                                  (zona) => zona.nombre !== PARTIDOS_EXTRA_ZONA_NAME
                                                ).length > 1 && <ZonaFixture zona={zona} />}
                                                <TorneoTabTablaPosiciones
                                                  tablaPosiciones={zona.tabla}
                                                ></TorneoTabTablaPosiciones>
                                              </div>
                                            );
                                          })}
                                      </PivotItem>
                                    );
                                  })}
                              </Pivot>
                            </PivotItem>
                          )}
                          <PivotItem
                            headerText="Goleadores"
                            itemKey={'3'}
                            onRenderItemLink={(itemProps) => {
                              return (
                                <div>
                                  <span>{itemProps?.headerText}</span>
                                </div>
                              );
                            }}
                          >
                            <TorneoTabGoleadores goleadores={torneo.goleadores}></TorneoTabGoleadores>
                          </PivotItem>
                        </Pivot>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <TorneoNotFound
        showTorneoNotFoundDialog={showTorneoNotFoundDialog}
        setShowTorneoNotFoundDialog={setShowTorneoNotFoundDialog}
      ></TorneoNotFound>
    </>
  );
};

export default TorneoDetailView;
