import Environment from '../lib/Environment';
import XhrRequestHandler from '../lib/XhrRequestHandler';
import XhrRequestCache from '../lib/XhrRequestCache';
import XhrErrorListener from '../lib/XhrErrorListener';
import CryptoJS from 'crypto-js';

function decrypt(encryptedText: string) {
  const env = new Environment();
  const parts = encryptedText.split(':');
  const iv = CryptoJS.enc.Hex.parse(parts[0]);
  const encrypted = CryptoJS.enc.Hex.parse(parts[1]);
  const key = CryptoJS.enc.Hex.parse(CryptoJS.enc.Base64.parse(env.passphrase()).toString(CryptoJS.enc.Hex));
  // @ts-ignore
  const decrypted = CryptoJS.AES.decrypt({ ciphertext: encrypted }, key, {
    iv: iv,
    mode: CryptoJS.mode.CTR,
    padding: CryptoJS.pad.NoPadding,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}

class SystemClient {
  private constructor(private requestHandler: XhrRequestHandler, private environment: Environment) {}

  static start(environment: Environment) {
    const requestHandler = new XhrRequestHandler(environment, XhrRequestCache.with([]));

    return new this(requestHandler, environment);
  }

  getEnvironment() {
    return this.environment;
  }

  unsubscribe(aListener: XhrErrorListener) {
    return this.requestHandler.unsubscribe(aListener);
  }

  subscribe(aListener: XhrErrorListener) {
    return this.requestHandler.subscribe(aListener);
  }

  async getContextMenu() {
    const context = await this.requestHandler.get<string>(`/get-context`);
    const decryptedData = decrypt(context);
    return JSON.parse(decryptedData) as ContextMenu[];
  }

  async getTorneosXDivision(federacionId: string, temporadaId: string, rama: string, categoriaId: string) {
    const torneosXDivision = await this.requestHandler.get<string>(
      `/torneos-x-division/${federacionId}/${temporadaId}/${rama}/${categoriaId}`
    );
    const decryptedData = decrypt(torneosXDivision);
    return JSON.parse(decryptedData).map((item: any) => item._doc) as TorneosXDivisionXFederacion[];
  }

  async getTorneo(torneoId: string) {
    const torneo = await this.requestHandler.get<string>(`/torneos/${torneoId}`);
    const decryptedData = decrypt(torneo);
    return JSON.parse(decryptedData) as Torneo;
  }
}

export const todosContraTodos = (fase: Fase) => fase.tipo === 'Todos contra todos';
export const grilla = (fase: Fase) => fase.tipo === 'Grilla' || fase.tipo === 'Grilla con puestos';

interface Categoria {
  id: string;
  nombre: string;
  cantTorneos: string;
}

export interface Rama {
  key: string;
  text: string;
  categorias: Categoria[];
}

export interface Federacion {
  id: string;
  nombre: string;
  ramas: Rama[];
}

export interface ContextMenu {
  id: string;
  actual: boolean;
  descripcion: string;
  federaciones: Federacion[];
}

export interface Torneo {
  id: string;
  detalle: {
    nombreTorneo: string;
    rama: string;
    federacion: {
      federacionId: string;
      federacionNombre: string;
    };
    categoria: {
      categoriaId: string;
      categoriaNombre: string;
    };
    temporada: {
      temporadaId: string;
      temporadaNombre: string;
    };
    division: {
      divisionId: string;
      divisionNombre: string;
    };
  };
  fases: Fase[];
  goleadores: Goleadores[];
}

export interface Fase {
  id: string;
  nombre: string;
  tipo: string;
  zonas: Zona[];
  modeloGrilla: ModeloGrilla[];
}

export interface Zona {
  id: string;
  nombre: string;
  partidos: Partido[];
  tabla: TablaPosiciones[];
}

export interface TablaPosiciones {
  puntos: number;
  club: string;
  club_escudo: string;
  partidosGanados: number;
  partidosJugados: number;
  partidosPerdidos: number;
  partidosEmpatados: number;
  partidosSinPresentacion: number;
  golesAFavor: number;
  golesEnContra: number;
  puesto: number;
  tieneQuitaPuntos: boolean;
}

export interface Partido {
  id: string;
  golesLocal: string;
  nombreLocal: string;
  escudoImagePathLocal: string;
  golesVisitante: string;
  nombreVisitante: string;
  escudoImagePathVisitante: string;
  campoJuegoNombre: string;
  horario: string;
  isGhostMatch: boolean;
  playing: boolean;
  played: boolean;
  extra: boolean;
  numeroFecha: string;
  planillas: Planilla[];
}

export interface ResumenEventos {
  nombre: string;
  goles: string;
  amarillas: string;
  dos_minutos: string;
  rojas: string;
  azules: string;
  penales: string;
}

export interface Equipo {
  nombre: string;
  goles: string;
  resumenEventosJugadores: ResumenEventos[];
  resumenEventosOficiales: ResumenEventos[];
  resumenEventosOficialesAuxiliares: ResumenEventos[];
  timeouts: string[];
}

export interface Planilla {
  resultado_directo: boolean;
  pdf: string;
  url_transmision: string;
  local: Equipo;
  visitante: Equipo;
}
export interface ModeloGrilla {
  mtg_id: string;
  mtg_tipo_grilla: string;
  mtg_cant_equipos: string;
  mtg_ronda: string;
  mtg_descripcion: string;
  mtg_partido_numero: string;
  mtg_local: string;
  mtg_visitante: string;
  mtg_llave_principal: boolean;
}

export interface Goleadores {
  id: string;
  jug_apellido: string;
  jug_nombre: string;
  jug_foto: string;
  club_nombre: string;
  club_escudo: string;
  goles: string;
  partidos: string;
  promedio: string;
  posicion: number;
}

export interface TorneosXDivision {
  id: string;
  torneos: { id: string; nombre: string; rama: string; categoria: { id: string; nombre: string } }[];
}

export interface TorneosXDivisionXFederacion {
  federacionId: string;
  federacionNombre: string;
  _id: string;
  divisiones: {
    id: string;
    nombre: string;
    torneos: {
      id: string;
      nombre: string;
      rama: string;
      categoriaId: string;
      temporadaId: string;
      _id: string;
    }[];
    _id: string;
  }[];
}

export default SystemClient;
