import React from 'react';
import {
  Facepile,
  IFacepilePersona,
  OverflowButtonType,
  Persona,
  PersonaInitialsColor,
  PersonaSize,
  Pivot,
  PivotItem,
  Text,
} from '@fluentui/react';
import { TorneosXDivisionXFederacion } from '../../system/SystemClient';
import { genkey, hash, unhash } from '../../common/constants';
import {
  AppContext,
  MenuFilters,
  CRITERIO_CATEGORIA,
  CRITERIO_FEDERACION,
  CRITERIO_RAMA,
  CRITERIO_TEMPORADA,
} from '../../AppInRouterContext';
import {
  DefaultPalette,
  Stack,
  IStackStyles,
  IStackTokens,
  IStackItemStyles,
  Dropdown,
  IDropdownOption,
  DropdownMenuItemType,
  Icon,
} from '@fluentui/react';
import { CryptoJSAesJson } from '../../lib/CryptoAes/cryptojs-aes-format';

import { useNavigate } from 'react-router-dom';
import { onRenderOptionCategoria, onRenderOptionRama } from '../Common/RenderOptions';
interface IResumenTorneoProps {
  menuFilters: MenuFilters;
  appContext: AppContext;
  onChangeFilters: (criterio: string, value: string) => void;

  categorias: IDropdownOption<any>[];
  temporadas: IDropdownOption<any>[];
  federaciones: IDropdownOption<any>[];
  ramas: IDropdownOption<any>[];
}

const ResumenTorneos = (props: IResumenTorneoProps) => {
  const [torneosPorDivision, setTorneosPorDivision] = React.useState<TorneosXDivisionXFederacion[]>([]);
  const [showDropdownTemporada, setShowDropdownTemporada] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getTorneosPorDivision = async () => {
      if (
        props.menuFilters.federacionId &&
        props.menuFilters.temporadaId &&
        props.menuFilters.categoriaId &&
        props.menuFilters.rama
      ) {
        try {
          props.appContext.changeBlockUI(true);
          const ret = await props.appContext.system.getTorneosXDivision(
            props.menuFilters.federacionId,
            props.menuFilters.temporadaId,
            props.menuFilters.rama,
            props.menuFilters.categoriaId
          );
          console.log('ret > ', ret);
          setTorneosPorDivision(ret);
        } catch (error) {
          console.log(error);
        } finally {
          props.appContext.changeBlockUI(false);
        }
      }
    };
    getTorneosPorDivision();
  }, [props.menuFilters]);

  const navigate = useNavigate();

  const onChange = (criterio: string, value: string): void => {
    props.onChangeFilters(criterio, value);
  };
  const [step, setStep] = React.useState<number>(
    props.appContext.system.getEnvironment().federacionDefault() ? 1 : 0
  );

  const assitantColumn = props.appContext.system.getEnvironment().federacionDefault()
    ? showDropdownTemporada
      ? '4'
      : '6'
    : showDropdownTemporada
    ? '3'
    : '4';

  return (
    <>
      <div className="ms-Grid mt-2" dir="ltr" style={{ marginBottom: 10 }}>
        <div className="ms-Grid-col ms-sm12">
          <div className="ms-Grid-row ">
            <div className="ms-Grid bg-white-h100 ms-depth-4" dir="ltr">
              <>
                <div className="ms-Grid-row padding-1">
                  <div className="ms-Grid-col ms-md8 ms-sm12">
                    <Text className="nombre-header-torneo">Bienvenido al resumen de torneos</Text>
                    <Text variant="smallPlus" className="mt-1" style={{ display: 'block' }}>
                      Acá vas a poder ver el fixture en vivo, tabla de posiciones y los goleadores de todos
                      los torneos organizados por la federación.
                    </Text>
                    <Text
                      variant="small"
                      className="link-styles clickable "
                      onClick={() => {
                        setShowDropdownTemporada(true);
                      }}
                    >
                      <Icon className="mr-1 mt-1 " iconName="Calendar"></Icon>
                      Ver otras temporadas
                    </Text>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      <div className="ms-Grid mt-2" dir="ltr" style={{ marginBottom: 10 }}>
        <div className="ms-Grid-col ms-sm12">
          <div className="ms-Grid-row ">
            <div className="ms-Grid bg-white-h100 ms-depth-4" dir="ltr">
              <>
                <div className="ms-Grid-row padding-1">
                  {showDropdownTemporada && (
                    <div className={`ms-Grid-col ms-sm${assitantColumn} ms-motion-scaleDownIn`}>
                      <Pivot aria-label="" className="customPivot">
                        <PivotItem headerText="Temporada" key={'2'}>
                          <div className="ms-Grid-col ms-sm12">
                            <Dropdown
                              selectedKey={props.menuFilters.temporadaId}
                              onChange={(e, opt) => {
                                onChange(CRITERIO_TEMPORADA, String(opt!.key));
                              }}
                              placeholder="Temporada"
                              options={props.temporadas}
                            />
                          </div>
                        </PivotItem>
                      </Pivot>
                    </div>
                  )}
                  {!props.appContext.system.getEnvironment().federacionDefault() && (
                    <div className={`ms-Grid-col ms-sm${assitantColumn} ms-motion-scaleDownIn`}>
                      <Pivot aria-label="" className="customPivot">
                        <PivotItem headerText="Federacion">
                          <div className="ms-Grid-col ms-sm12">
                            <Dropdown
                              selectedKey={props.menuFilters.federacionId}
                              onChange={(e, opt) => {
                                onChange(CRITERIO_FEDERACION, String(opt!.key));
                              }}
                              placeholder="Federación"
                              options={props.federaciones}
                              dropdownWidth={'auto'}
                            />
                          </div>
                        </PivotItem>
                      </Pivot>
                    </div>
                  )}

                  {props.menuFilters.federacionId && (
                    <div className={`ms-Grid-col ms-sm${assitantColumn} ms-motion-scaleDownIn`}>
                      <Pivot aria-label="" className="customPivot">
                        <PivotItem headerText="Rama">
                          <div className="ms-Grid-col ms-sm12">
                            <Dropdown
                              selectedKey={props.menuFilters.rama}
                              onChange={(e, opt) => {
                                onChange(CRITERIO_RAMA, String(opt!.key));
                              }}
                              placeholder="Rama"
                              options={props.ramas}
                              // @ts-ignore
                              onRenderOption={onRenderOptionRama}
                            />
                          </div>
                        </PivotItem>
                      </Pivot>
                    </div>
                  )}
                  {props.menuFilters.rama && (
                    <div className={`ms-Grid-col ms-sm${assitantColumn} ms-motion-scaleDownIn`}>
                      <Pivot aria-label="" className="customPivot">
                        <PivotItem headerText="Categoria">
                          <div className="ms-Grid-col ms-sm12">
                            <Dropdown
                              selectedKey={props.menuFilters.categoriaId}
                              onChange={(e, opt) => {
                                onChange(CRITERIO_CATEGORIA, String(opt!.key));
                              }}
                              placeholder="Categoria"
                              options={props.categorias}
                              // @ts-ignore
                              onRenderOption={onRenderOptionCategoria}
                            />
                          </div>
                        </PivotItem>
                      </Pivot>
                    </div>
                  )}
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      {!props.menuFilters.federacionId ||
      !props.menuFilters.temporadaId ||
      !props.menuFilters.categoriaId ||
      !props.menuFilters.rama ? (
        <div className="ms-Grid mt-2" dir="ltr" style={{ marginBottom: 10 }}>
          <div className="ms-Grid-col ms-sm12">
            <div className="ms-Grid-row ">
              <div className="ms-Grid bg-white-h100 ms-depth-4" dir="ltr">
                <>
                  <div className="ms-Grid-row padding-1">
                    <div className="ms-Grid-col ms-sm12 text-center">
                      <Text variant="medium">
                        Podes buscar los torneos aplicando los filtros en el menu de arriba
                      </Text>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="ms-Grid mt-2" dir="ltr" style={{ marginBottom: 10, paddingLeft: 0, paddingRight: 0 }}>
          <div className="ms-Grid-col ms-sm12">
            <div className="ms-Grid bg-white-h100 ms-depth-4 ms-motion-scaleDownIn" dir="ltr">
              <div className="ms-Grid-row padding-1">
                <div className="ms-Grid-col ms-sm12 text-center">
                  <Text variant="medium">Estos son los torneos para los filtros aplicados</Text>
                  <hr></hr>
                  {torneosPorDivision.map((txd) => {
                    return (
                      <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                          {txd.divisiones.map((d, i) => {
                            return (
                              <div key={genkey()}>
                                {d.torneos.length > 0 && (
                                  <div>
                                    <Text className="fecha-label" variant="mediumPlus">
                                      {d.nombre}
                                    </Text>
                                    <Stack
                                      enableScopedSelectors
                                      horizontal
                                      wrap
                                      tokens={{ childrenGap: 10 }}
                                      horizontalAlign="center" // Centra horizontalmente
                                      className="mt-2 mb-2"
                                    >
                                      {d.torneos.map((torneo) => {
                                        return (
                                          <Stack.Item align="center" className="mt-1 ">
                                            <div
                                              className="bg-white-h100 ms-motion-scaleDownIn tournament-box clickable"
                                              onClick={() => {
                                                let password = props.appContext.system
                                                  .getEnvironment()
                                                  .passphrase();
                                                const idHashed = hash(torneo.id, password);
                                                navigate(`/${idHashed}`);
                                              }}
                                            >
                                              <Text
                                                variant="mediumPlus"
                                                className="text-overflow"
                                                style={{
                                                  fontWeight: 500,
                                                  display: 'block',
                                                  color: '#2788d5',
                                                }}
                                                key={genkey()}
                                              >
                                                {torneo.nombre}
                                              </Text>
                                            </div>
                                          </Stack.Item>
                                        );
                                      })}
                                    </Stack>{' '}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResumenTorneos;
