import React from 'react';
import { Pivot, PivotItem, Text, IconButton, Icon } from '@fluentui/react';
import ClubImageCircle from '../Common/ClubImageCircle';
import { todosContraTodos, Torneo, Zona } from '../../system/SystemClient';
import { genkey, PARTIDOS_EXTRA_ZONA_NAME } from '../../common/constants';

export const ZonaFixture = ({ zona }: { zona: Zona }) => {
  return (
    <div>
      <div className="ms-Grid-col ms-sm12 text-center" style={{ borderTop: '1px solid #e8e8e8' }}>
        <div className="ms-Grid-row" style={{ marginTop: 15, marginBottom: 15 }}>
          <div className="ms-Grid-col ms-sm12">
            <Text variant="large" className="zona-label">
              {zona.nombre !== PARTIDOS_EXTRA_ZONA_NAME ? `Zona ${zona.nombre}` : `Partidos extras`}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FechaFixture = ({ fecha }: { fecha: String }) => {
  return (
    <div>
      <div className="ms-Grid-col ms-sm12 instancia-fecha">
        <div className="ms-Grid-row" style={{ marginTop: 10, marginBottom: 10 }}>
          <div className="ms-Grid-col ms-sm12">
            <Text variant="large" className="fecha-label">
              Fecha {fecha}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
