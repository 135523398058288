import React from 'react';
import { Outlet } from 'react-router-dom';
import { Stack, IStackStyles, MessageBar, MessageBarType, IDropdownOption, Image } from '@fluentui/react';

import Header from './Header';
import Sidebar from './Sidebar';
import MessageCourier from '../../../lib/MessageCourier';
import { MessageReceiver } from '../../../lib/MessageReceiver';
import SystemClient from '../../../system/SystemClient';
import { AppContext, MenuFilters } from '../../../AppInRouterContext';
// import { AdImages, adImages, imageBackground, USA_PUBLICIDAD } from '../../../common/configurable';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { AdImages, loadConfig } from '../../../common/configurable';
import { Carousel as CarouselNative } from '../Carousel';

const mainStackStyles: Partial<IStackStyles> = {};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

interface IBaseProps {
  appContext: AppContext;
}

export interface IConfigLayout {
  adImages: AdImages[];
  headerImages: AdImages[];
  headerColor: string;
  imageBackground: string;
  logoHeaderPath: string;
}

interface IBaseState {
  isSidebarOpen: boolean;
  abortController?: AbortController;
  message?: string;
  messageType?: MessageBarType;
  config: IConfigLayout;
}

class Base extends React.Component<IBaseProps, IBaseState> implements MessageReceiver {
  constructor(props: IBaseProps) {
    super(props);
    this.state = {
      isSidebarOpen: false,
      config: {
        adImages: [],
        headerImages: [],
        headerColor: '#fff',
        imageBackground: '',
        logoHeaderPath: '',
      },
    };
  }

  componentDidMount(): void {
    if (this.props.appContext.courier) {
      this.props.appContext.courier.subscribe(this);
    }

    const fetchConfig = async () => {
      const configData = await loadConfig();
      this.setState({ ...this.state, config: configData });
    };

    fetchConfig();
  }

  receiveError(message: string, _error: unknown): void {
    this.setState({ message, messageType: MessageBarType.error });
    setTimeout(() => {
      this.onDismissMessage();
    }, 30000);
  }

  receiveSuccess(message: string): void {
    this.setState({ message, messageType: MessageBarType.success });
    setTimeout(() => {
      this.onDismissMessage();
    }, 30000);
  }

  private onDismissMessage = () => {
    this.setState({ message: undefined });
  };

  render() {
    const { isSidebarOpen, message, messageType, config } = this.state;
    return (
      <div
        style={{
          backgroundImage: config?.imageBackground ? `url(${config?.imageBackground})` : '',
          backgroundSize: 'cover', // Ajusta la imagen para cubrir todo el contenedor
          backgroundPosition: 'center', // Centra la imagen
          width: '100%', // Ancho del contenedor
        }}
      >
        <Stack
          styles={{
            root: {
              width: '100%',
              margin: '0 auto',
              height: '5 vh',
              alignItems: 'center',
              background: config?.imageBackground ? 'transparent' : '#1b45a9',
            },
          }}
          className="ms-depth-16"
        >
          <Header
            onMenuButtonClick={() => this.openSibedar()}
            appContext={this.props.appContext}
            headerColor={config?.headerColor}
            logoPath={config?.logoHeaderPath}
            headerImages={config?.headerImages}
          />
        </Stack>
        <Stack
          styles={{
            root: {
              width: '100%',
              margin: '0 auto',
              height: '95 vh',
              alignItems: 'center',
              background: config?.imageBackground ? 'transparent' : '#faf9f8',
            },
          }}
        >
          <section
            className={
              config?.adImages && config?.adImages.length > 0 ? 'section-container-ad' : 'section-container'
            }
          >
            {message && (
              <MessageBar
                messageBarType={messageType}
                isMultiline={false}
                onDismiss={this.onDismissMessage}
                dismissButtonAriaLabel="Close"
              >
                {message}
              </MessageBar>
            )}
            <Outlet />
          </section>
          <div style={{ width: '100%', borderTop: '2px solid rgb(91, 91, 91)', background: '#1b45a9' }}>
            <CarouselNative images={config.adImages} />
          </div>
          {/* <div>
            <Carousel
              swipeable={false}
              draggable={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              transitionDuration={500}
              containerClass="carousel-container"
              showDots={false}
              arrows={false}
              customTransition="all 2s" // Ajusta la transición aquí si es necesario
            >
              {adImages.map((ad: AdImages, i) => {
                return (
                  <div
                    key={i}
                    className="container-ad-div-item clickable"
                    onClick={() => {
                      if (ad.redirectTo) {
                        window.open(ad.redirectTo, '_blank');
                      }
                    }}
                  >
                    <Image height={'8vh'} src={ad.imagePath} />
                  </div>
                );
              })}
            </Carousel>
          </div> */}
          {/* )} */}
        </Stack>
      </div>
    );
  }

  private openSibedar() {
    this.setState({ isSidebarOpen: true });
  }

  private closeSibedar() {
    this.setState({ isSidebarOpen: false });
  }
}
export default Base;
