import React from 'react';
import logo from '../logo.svg';
import '../App.css';

export const NotFound = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>404 Page not found.</p>
      </header>
    </div>
  );
};
