import React from 'react';
import { Pivot, PivotItem, Text, IconButton, Icon, DirectionalHint } from '@fluentui/react';
import ClubImageCircle from '../Common/ClubImageCircle';
import { todosContraTodos, Torneo, Partido, Planilla } from '../../system/SystemClient';
import { genkey } from '../../common/constants';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Callout, Link, mergeStyleSets, FontWeights } from '@fluentui/react';
import { DateTime } from 'luxon';

export const PartidoFixture = ({ partido }: { partido: Partido }) => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId(genkey());
  const labelId = useId(genkey());
  const descriptionId = useId(genkey());

  const [isCalloutPlanillaVisibleDesktop, { toggle: toggleIsCalloutVisibleDesktop }] = useBoolean(false);
  const buttonIdDesktop = useId(genkey());
  const labelIdDesktop = useId(genkey());
  const descriptionIdDesktop = useId(genkey());

  const [isCalloutCampoJuegoVisibleDesktop, { toggle: toggleIsCalloutCampoJuegoVisibleDesktop }] =
    useBoolean(false);
  const labelIdCampoJuegoDesktop = useId(genkey());
  const descriptionIdCampoJuegoDesktop = useId(genkey());
  const buttonIdCampoJuegoDesktop = useId(genkey());

  const [isCalloutPlanillaVisibleMobile, { toggle: toggleIsCalloutVisibleMobile }] = useBoolean(false);
  const buttonIdMobile = useId(genkey());
  const labelIdMobile = useId(genkey());
  const descriptionIdMobile = useId(genkey());

  const [isCalloutCampoJuegoVisibleMobile, { toggle: toggleIsCalloutCampoJuegoVisibleMobile }] =
    useBoolean(false);
  const labelIdCampoJuegoMobile = useId(genkey());
  const descriptionIdCampoJuegoMobile = useId(genkey());
  const buttonIdCampoJuegoMobile = useId(genkey());

  const winnerClass = (golesPropios: number, golesAComparar: number) => {
    return golesPropios > golesAComparar;
  };

  const openCalloutOrOpenVideo = (planillas: Planilla[], openCallout: () => void) => {
    const cantTransmisiones = planillas.filter((planilla) => !!planilla.url_transmision).length;
    if (cantTransmisiones === 1) {
      const unicaTransmision = planillas
        .filter((planilla) => !!planilla.url_transmision)
        .map((planilla) => planilla.url_transmision);
      window.open(unicaTransmision[0], '_blank');
    } else {
      openCallout();
    }
  };

  const openCalloutOrOpenPDF = (planillas: Planilla[], openCallout: () => void) => {
    const cantPDFs = planillas.filter((planilla) => !!planilla.pdf).length;
    if (cantPDFs === 1) {
      const unicoPDF = planillas.filter((planilla) => !!planilla.pdf).map((planilla) => planilla.pdf);
      window.open(unicoPDF[0], '_blank');
    } else {
      openCallout();
    }
  };

  console.log('partido > ', partido);

  return (
    <div className="ms-Grid-row text-center" style={{ marginTop: 5, marginBottom: 5 }} key={genkey()}>
      {/* =====================================================================
                              VISTA DESKTOP
        =====================================================================*/}
      <div id="viewDesktop" className="ms-hiddenSm ">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 mt-1">
            <div className="ms-Grid-col ms-sm2  text-overflow " style={{ marginTop: 9, width: '14.66%' }}>
              <Text
                style={{
                  fontSize: 14,
                  float: 'left',
                  marginLeft: 10,
                }}
                className="mb-1"
                variant="medium"
              >
                {!partido.isGhostMatch && (
                  <>
                    <Icon
                      iconName="Calendar"
                      className="text-icon"
                      style={{ fontSize: '16px !important' }}
                    ></Icon>
                    {DateTime.fromFormat(partido.horario, 'yyyy/LL/dd HH:mm:ss')
                      .setLocale('es')
                      .toFormat('ccc dd LLLL HH:mm')}
                  </>
                )}
              </Text>
            </div>

            <div className="ms-Grid-col ms-sm3 text-overflow" style={{ minHeight: 40, width: '30%' }}>
              <div className="ms-Grid-col ms-sm10 text-overflow" style={{ minHeight: 40 }}>
                <Text className="mb-1 nombre-equipo-fixture nombre-local" variant="medium">
                  {partido.nombreLocal}
                </Text>
              </div>

              <div className="ms-Grid-col ms-sm2" style={{ minHeight: 40 }}>
                <div>
                  <ClubImageCircle
                    escudo={partido.escudoImagePathLocal}
                    width={35}
                    height={35}
                    right
                  ></ClubImageCircle>
                </div>
              </div>
            </div>
            <div
              className={`ms-Grid-col ms-sm2 ${partido.playing ? 'cool-link' : ''}`}
              style={{ marginTop: 5, width: '11%', minWidth: 90 }}
            >
              {!partido.isGhostMatch && (partido.played || partido.playing) ? (
                <div style={{ fontWeight: 600, color: 'black', fontSize: 18, display: 'inline-flex' }}>
                  <div
                    className={
                      winnerClass(+partido.golesLocal, +partido.golesVisitante)
                        ? 'winner score'
                        : 'loser score'
                    }
                  >
                    {partido.golesLocal}
                  </div>{' '}
                  <div
                    className={
                      winnerClass(+partido.golesVisitante, +partido.golesLocal)
                        ? 'winner score'
                        : 'loser score'
                    }
                  >
                    {partido.golesVisitante}
                  </div>
                </div>
              ) : (
                <span>Vs</span>
              )}
            </div>
            <div className="ms-Grid-col ms-sm3 text-overflow" style={{ minHeight: 40, width: '30%' }}>
              <div className="ms-Grid-col ms-sm2" style={{ minHeight: 40 }}>
                <div>
                  <ClubImageCircle
                    escudo={partido.escudoImagePathVisitante}
                    width={35}
                    height={35}
                  ></ClubImageCircle>
                </div>
              </div>

              <div className="ms-Grid-col ms-sm10 text-overflow" style={{ minHeight: 40 }}>
                <Text className="mb-1 nombre-equipo-fixture nombre-visitante" variant="medium">
                  {!partido.isGhostMatch ? partido.nombreVisitante : 'LIBRE'}
                </Text>
              </div>
            </div>
            <div
              className="ms-Grid-col ms-sm2  text-overflow "
              style={{ marginTop: 9, width: '13.66%', textAlign: 'left' }}
            >
              {!partido.isGhostMatch && (
                <div className="ms-Grid-col ms-sm12  text-overflow " style={{ textAlign: 'left' }}>
                  <PrimaryButton
                    iconProps={{ iconName: 'POI' }}
                    title="Ver estadio"
                    id={buttonIdCampoJuegoDesktop}
                    className="float-right ml-2 "
                    onClick={toggleIsCalloutCampoJuegoVisibleDesktop}
                    styles={{
                      root: {
                        border: '1px solid #009454',
                        background: '#009454',
                        borderRadius: 25,
                        padding: 0,
                        minWidth: 25,
                        height: 27,
                      },
                    }}
                  ></PrimaryButton>

                  <PrimaryButton
                    iconProps={{ iconName: 'AccountActivity' }}
                    id={buttonIdDesktop}
                    onClick={() => openCalloutOrOpenPDF(partido.planillas, toggleIsCalloutVisibleDesktop)}
                    styles={{ root: { borderRadius: 25, padding: 0, minWidth: 25, height: 27 } }}
                    title="Ver planilla"
                    className="float-right ml-2 "
                    disabled={partido.planillas.filter((planilla) => planilla.pdf).length === 0}
                  ></PrimaryButton>

                  {partido.planillas.some((planilla) => planilla.url_transmision) && (
                    <PrimaryButton
                      iconProps={{ iconName: 'Video' }}
                      className="float-right "
                      title="Ver transmisión"
                      id={buttonIdDesktop}
                      onClick={() => openCalloutOrOpenVideo(partido.planillas, toggleIsCalloutVisibleDesktop)}
                      styles={{
                        root: {
                          border: '1px solid #f79c25',
                          background: '#f79c25',
                          borderRadius: 25,
                          padding: 0,
                          minWidth: 25,
                          height: 27,
                        },
                      }}
                    ></PrimaryButton>
                  )}

                  <>
                    {isCalloutPlanillaVisibleDesktop && (
                      <Callout
                        ariaLabelledBy={labelIdDesktop}
                        ariaDescribedBy={descriptionIdDesktop}
                        role="dialog"
                        gapSpace={0}
                        target={`#${buttonIdDesktop}`}
                        onDismiss={toggleIsCalloutVisibleDesktop}
                        setInitialFocus
                        className={styles.callout}
                        directionalHint={DirectionalHint.leftCenter}
                      >
                        <PlanillasCallout partido={partido} labelId={labelIdDesktop}></PlanillasCallout>
                      </Callout>
                    )}
                  </>

                  <>
                    {isCalloutCampoJuegoVisibleDesktop && (
                      <Callout
                        ariaLabelledBy={labelIdCampoJuegoDesktop}
                        ariaDescribedBy={descriptionIdCampoJuegoDesktop}
                        role="dialog"
                        gapSpace={0}
                        target={`#${buttonIdCampoJuegoDesktop}`}
                        onDismiss={toggleIsCalloutCampoJuegoVisibleDesktop}
                        setInitialFocus
                        className={styles.callout}
                        directionalHint={DirectionalHint.leftCenter}
                      >
                        <span style={{ margin: 10, fontSize: 14, fontWeight: 500 }}>
                          {partido.campoJuegoNombre}
                        </span>
                      </Callout>
                    )}
                  </>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* =====================================================================
                              VISTA MOBILE
        =====================================================================*/}

      <div
        id="viewMobile"
        className="ms-hiddenMdUp mt-1"
        style={{ borderTop: '1px solid #e6e6e6', marginTop: 5 }}
      >
        <div className="ms-Grid-col ms-sm5 text-overflow text-left" style={{ marginTop: 5 }}>
          <Text className="mb-1" variant="small">
            <Icon iconName="Calendar" style={{ marginLeft: 5 }} className="text-icon-small"></Icon>
            {partido.horario}
          </Text>
        </div>
        <div className="ms-Grid-col ms-sm7 text-overflow text-left" style={{ marginTop: 5 }}>
          <PrimaryButton
            iconProps={{ iconName: 'POI' }}
            title="Ver estadio"
            id={buttonIdCampoJuegoMobile}
            className="float-right"
            onClick={toggleIsCalloutCampoJuegoVisibleMobile}
            styles={{
              root: {
                border: '1px solid #009454',
                background: '#009454',
                borderRadius: 25,
                padding: 0,
                minWidth: 23,
                height: 26,
                marginLeft: 5,
              },
            }}
          ></PrimaryButton>

          <PrimaryButton
            iconProps={{ iconName: 'AccountActivity' }}
            id={buttonIdMobile}
            onClick={() => openCalloutOrOpenPDF(partido.planillas, toggleIsCalloutVisibleMobile)}
            styles={{ root: { borderRadius: 25, padding: 0, minWidth: 23, height: 26, marginLeft: 5 } }}
            title="Ver planilla"
            className="float-right"
            disabled={partido.planillas.filter((planilla) => planilla.pdf).length === 0}
          ></PrimaryButton>

          {partido.planillas.some((planilla) => planilla.url_transmision) && (
            <PrimaryButton
              iconProps={{ iconName: 'Video' }}
              className="float-right "
              title="Ver transmisión"
              id={buttonIdMobile + 'M'}
              onClick={() => openCalloutOrOpenVideo(partido.planillas, toggleIsCalloutVisibleMobile)}
              styles={{
                root: {
                  border: '1px solid #f79c25',
                  background: '#f79c25',
                  borderRadius: 25,
                  padding: 0,
                  minWidth: 23,
                  height: 26,
                },
              }}
            ></PrimaryButton>
          )}

          <>
            {isCalloutPlanillaVisibleMobile && (
              <Callout
                ariaLabelledBy={labelIdMobile}
                ariaDescribedBy={descriptionIdMobile}
                role="dialog"
                gapSpace={0}
                target={`#${buttonIdMobile}`}
                onDismiss={toggleIsCalloutVisibleMobile}
                setInitialFocus
                className={styles.callout}
                directionalHint={DirectionalHint.leftCenter}
              >
                <PlanillasCallout partido={partido} labelId={labelIdMobile}></PlanillasCallout>
              </Callout>
            )}
          </>

          <>
            {isCalloutCampoJuegoVisibleMobile && (
              <Callout
                ariaLabelledBy={labelIdCampoJuegoMobile}
                ariaDescribedBy={descriptionIdCampoJuegoMobile}
                role="dialog"
                gapSpace={0}
                target={`#${buttonIdCampoJuegoMobile}`}
                onDismiss={toggleIsCalloutCampoJuegoVisibleMobile}
                setInitialFocus
                className={styles.callout}
                directionalHint={DirectionalHint.leftCenter}
              >
                <span style={{ margin: 10, fontSize: 14, fontWeight: 500 }}>{partido.campoJuegoNombre}</span>
              </Callout>
            )}
          </>
        </div>

        <div className="ms-Grid-col ms-sm12" style={{ minHeight: 30 }}>
          <TeamMobile
            escudo={partido.escudoImagePathLocal}
            nombre={partido.nombreLocal}
            goles={partido.played || partido.playing ? partido.golesLocal : '-'}
            winner={partido.golesLocal > partido.golesVisitante}
          ></TeamMobile>
          <TeamMobile
            escudo={partido.escudoImagePathVisitante}
            nombre={partido.nombreVisitante}
            goles={partido.played || partido.playing ? partido.golesVisitante : '-'}
            winner={partido.golesVisitante > partido.golesLocal}
          ></TeamMobile>
        </div>
      </div>
    </div>
  );
};

const TeamMobile = (props: { escudo: string; nombre: string; goles: string; winner: boolean }) => {
  return (
    <div className="ms-Grid-row mt-1">
      <div className="ms-Grid-col ms-sm1 text-overflow" style={{ minHeight: 30 }}>
        <ClubImageCircle escudo={props.escudo} width={25} height={25}></ClubImageCircle>
      </div>
      <div className="ms-Grid-col ms-sm9 text-overflow" style={{ minHeight: 30 }}>
        <Text
          className={`mb-1  text-overflow nombre-local text-left ${
            props.winner ? ' nombre-equipo-fixture-mobile-winner' : ' nombre-equipo-fixture-mobile'
          }`}
          variant="medium"
        >
          {props.nombre}
        </Text>
      </div>
      <div className={`ms-Grid-col ms-sm2 text-overflow }`} style={{ minHeight: 30 }}>
        <div className={`${props.winner ? 'winner-mobile' : 'loser-mobile'}`}>
          {props.goles}
          {/* {props.winner && <Icon iconName="ChevronLeftMed"></Icon>} */}
        </div>
      </div>
    </div>
  );
};

const styles = mergeStyleSets({
  button: {
    width: 130,
  },
  callout: {
    width: 420,
    maxWidth: '90%',
    padding: '6px 10px',
  },
  title: {
    marginBottom: 12,
    fontWeight: FontWeights.semilight,
  },
  link: {
    display: 'block',
    marginTop: 20,
  },
});

const PlanillasCallout = ({ partido, labelId }: { partido: Partido; labelId: any }) => {
  return (
    <div className="ms-Grid" dir="ltr">
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12">
          <Text block variant="mediumPlus" style={{ fontWeight: 600 }} className="text-center" id={labelId}>
            Elegí la planilla que quieras ver
          </Text>
        </div>
        <div className="ms-Grid-col ms-sm12">
          <hr></hr>
        </div>
      </div>
      {partido.planillas.map((planilla) => {
        return (
          <div className="ms-Grid-row mt-1">
            <div className="ms-Grid-col ms-sm1" style={{ paddingRight: 0 }}>
              <ClubImageCircle
                escudo={partido.escudoImagePathLocal}
                width={25}
                height={25}
                right
              ></ClubImageCircle>
            </div>

            <div
              className="ms-Grid-col ms-sm3 text-center"
              style={{
                marginTop: 3,
                fontWeight: 600,
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              {planilla.local.goles} - {planilla.visitante.goles}
            </div>
            <div className="ms-Grid-col ms-sm1" style={{ paddingLeft: 0 }}>
              <ClubImageCircle
                escudo={partido.escudoImagePathVisitante}
                width={25}
                height={25}
              ></ClubImageCircle>
            </div>

            <div className="ms-Grid-col ms-sm7 text-center">
              <PrimaryButton
                disabled={!!!planilla.pdf}
                onClick={() => {
                  window.open(planilla.pdf, '_blank');
                }}
                iconProps={{ iconName: planilla.pdf ? 'AccountActivity' : 'Cancel' }}
                title="Ver planilla"
                styles={{
                  root: {
                    borderRadius: 25,
                    padding: 0,
                    minWidth: 25,
                    height: 27,
                  },
                }}
              ></PrimaryButton>
              <PrimaryButton
                disabled={!!!planilla.url_transmision}
                onClick={() => {
                  window.open(planilla.url_transmision, '_blank');
                }}
                iconProps={{ iconName: planilla.url_transmision ? 'Video' : 'Cancel' }}
                className="ml-2"
                title="Ver transmisión"
                styles={{
                  root: {
                    borderRadius: 25,
                    padding: 0,
                    minWidth: 25,
                    height: 27,
                  },
                }}
              ></PrimaryButton>
            </div>
          </div>
        );
      })}
    </div>
  );
};
