import React from 'react';
import { DetailsList, DetailsListLayoutMode, SelectionMode, Text } from '@fluentui/react';
import ClubImageCircle from '../Common/ClubImageCircle';
import JugadorImageCircle from '../Common/JugadorImageCircle';
import { Goleadores } from '../../system/SystemClient';

const TorneoTabGoleadores = ({ goleadores }: { goleadores: Goleadores[] }) => {
  const getCols = (compact?: boolean) => {
    const columnsDesktop = [
      {
        key: 'posicion',
        name: compact ? '#' : 'Ranking',
        fieldName: 'posicion',
        minWidth: compact ? 10 : 70,
        maxWidth: compact ? 10 : 70,
        isResizable: true,
        onRender: (item: Goleadores) => {
          return <span style={{ fontSize: compact ? 12 : 20 }}>{item.posicion}</span>;
        },
      },
      {
        key: 'JugadorFoto',
        name: compact ? '' : 'Jugador',
        fieldName: 'JugadorFoto',
        minWidth: compact ? 20 : 80,
        maxWidth: compact ? 20 : 80,
        isResizable: true,
        onRender: (item: Goleadores) => {
          return (
            <div>
              <JugadorImageCircle
                escudo={item.jug_foto}
                width={compact ? 20 : 25}
                height={compact ? 20 : 25}
              ></JugadorImageCircle>
            </div>
          );
        },
      },
      {
        key: 'Jugador',
        name: compact ? 'Jugador' : '',
        fieldName: 'Jugador',
        minWidth: compact ? 75 : 350,
        maxWidth: compact ? 75 : 350,
        isResizable: true,
        onRender: (item: Goleadores) => {
          return (
            <span style={{ fontSize: compact ? 12 : 16, marginTop: 3 }}>
              {item.jug_apellido}, {item.jug_nombre}
            </span>
          );
        },
      },

      {
        key: 'club_nombre',
        name: 'Club',
        fieldName: 'club_nombre',
        minWidth: compact ? 30 : 300,
        maxWidth: compact ? 30 : 300,
        isResizable: true,
        onRender: (item: Goleadores) => {
          return (
            <div>
              <div style={{ display: 'inline-block' }}>
                <ClubImageCircle
                  escudo={item.club_escudo}
                  width={compact ? 20 : 25}
                  height={compact ? 20 : 25}
                ></ClubImageCircle>
              </div>
              {!compact && (
                <span
                  style={{
                    fontSize: 16,
                    marginTop: 0,
                    display: 'inline-block',
                    position: 'relative',
                    bottom: 10,
                    left: 10,
                  }}
                >
                  {item.club_nombre}
                </span>
              )}
            </div>
          );
        },
      },

      {
        key: 'goles',
        name: 'Goles',
        fieldName: 'goles',
        minWidth: compact ? 40 : 80,
        maxWidth: compact ? 40 : 80,
        isResizable: true,
        onRender: (item: Goleadores) => {
          return <span style={{ fontSize: compact ? 12 : 16, marginTop: 3 }}>{item.goles}</span>;
        },
      },
      {
        key: 'partidos',
        name: 'Partidos',
        fieldName: 'partidos',
        minWidth: compact ? 40 : 80,
        maxWidth: compact ? 40 : 80,
        isResizable: true,
        onRender: (item: Goleadores) => {
          return <span style={{ fontSize: compact ? 12 : 16, marginTop: 3 }}>{item.partidos}</span>;
        },
      },

      {
        key: 'promedio',
        name: 'Promedio',
        fieldName: 'promedio',
        minWidth: compact ? 45 : 80,
        maxWidth: compact ? 45 : 80,
        isResizable: true,
        onRender: (item: Goleadores) => {
          return <span style={{ fontSize: compact ? 12 : 16, marginTop: 3 }}>{item.promedio}</span>;
        },
      },
    ];

    return columnsDesktop;
  };
  return (
    <>
      <div className="ms-Grid-row ">
        {goleadores.length > 0 ? (
          <>
            {/* =====================================================================
                              VISTA DESKTOP
        =====================================================================*/}
            <div id="viewDesktop" className="ms-hiddenSm">
              <div className="ms-Grid-col ms-sm12 ">
                <DetailsList
                  onShouldVirtualize={() => false}
                  items={goleadores}
                  columns={getCols()}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionMode={SelectionMode.none}
                />
              </div>
            </div>
            {/* =====================================================================
                              VISTA MOBILE
        =====================================================================*/}

            <div id="viewMobile" className="ms-hiddenMdUp">
              <div className="ms-Grid-col ms-sm12 ">
                <DetailsList
                  onShouldVirtualize={() => false}
                  items={goleadores}
                  columns={getCols(true)}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionMode={SelectionMode.none}
                  compact
                />
              </div>
            </div>
          </>
        ) : (
          <div className="ms-Grid-col ms-sm12 mt-2">
            <Text variant="medium">Todavía no hay goleadores para este torneo.</Text>
          </div>
        )}
      </div>
    </>
  );
};

export default TorneoTabGoleadores;
