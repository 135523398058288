import React from 'react';
import { Pivot, PivotItem, Text, IconButton, Icon, DirectionalHint } from '@fluentui/react';
import ClubImageCircle from '../Common/ClubImageCircle';
import { todosContraTodos, Torneo, Partido } from '../../system/SystemClient';
import { genkey } from '../../common/constants';
import DialogTemplate from './DialogTemplate';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Callout, Link, mergeStyleSets, FontWeights } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';

interface ITorneoNotFound {
  showTorneoNotFoundDialog: boolean;
  setShowTorneoNotFoundDialog: (v: boolean) => void;
}

export const TorneoNotFound = (props: ITorneoNotFound) => {
  const navigate = useNavigate();
  return (
    <DialogTemplate
      showDialog={props.showTorneoNotFoundDialog}
      toggleShowDialog={() => {
        props.setShowTorneoNotFoundDialog(!props.showTorneoNotFoundDialog);
        navigate('/');
      }}
      title={'No se encontro el torneo'}
      onAccept={() => {
        navigate('/');
      }}
      onCancel={() => {
        props.setShowTorneoNotFoundDialog(!props.showTorneoNotFoundDialog);
        navigate('/');
      }}
      acceptText={'Volver'}
      cancelText={''}
      hideCancelButton
    >
      <></>
    </DialogTemplate>
  );
};
