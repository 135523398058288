import { Persona } from '@fluentui/react';
import {
  IDropdownOption,
  IFacepilePersona,
  PersonaInitialsColor,
  Facepile,
  OverflowButtonType,
  PersonaSize,
} from 'office-ui-fabric-react';

export const onRenderOptionRama = (option: IDropdownOption): JSX.Element => {
  const personas: IFacepilePersona[] = [
    {
      imageInitials: '',
      personaName: '',
      data: null,
      initialsColor: option.key == 'M' ? PersonaInitialsColor.blue : PersonaInitialsColor.pink, // Asigna el color generado
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <span
        style={{
          position: 'relative',
          top: 2,
        }}
      >
        {option.text}
      </span>
      <span style={{ float: 'left' }}>
        <Facepile
          personas={personas} // Pasamos la lista de personas
          maxDisplayablePersonas={1} // Mostrar solo 1 item
          overflowButtonType={OverflowButtonType.none} // No mostrar botón de desbordamiento
          onRenderPersona={(props) => (
            <Persona {...props} size={PersonaSize.size24} onRenderPrimaryText={() => <></>}></Persona>
          )}
        />
      </span>
    </div>
  );
};

// Lista de colores predefinidos
// Función para obtener un número de 1 a 24 basado en las iniciales
function getColorIndexFromInitials(categoria: string) {
  switch (categoria.replace(/ .*/, '').trim()) {
    case 'Cadetes':
      return PersonaInitialsColor.darkGreen;
    case 'Infantiles':
      return PersonaInitialsColor.darkBlue;
    case 'Juniors':
      return PersonaInitialsColor.purple;
    case 'Juveniles':
      return PersonaInitialsColor.lightBlue;
    case 'Mayores':
      return PersonaInitialsColor.pink;
    case 'Menores':
      return PersonaInitialsColor.rust;
  }
}

export const onRenderOptionCategoria = (option: IDropdownOption): JSX.Element => {
  const initials = option.text.slice(0, 3).toLocaleUpperCase();
  const color = getColorIndexFromInitials(option.text);

  const personas: IFacepilePersona[] = [
    {
      imageInitials: initials,
      personaName: option.text,
      data: null,
      initialsColor: color, // Asigna el color generado
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <span
        style={{
          position: 'relative',
          top: 2,
        }}
      >
        {option.text}
      </span>
      <span style={{ float: 'left' }}>
        <Facepile
          personas={personas} // Pasamos la lista de personas
          maxDisplayablePersonas={1} // Mostrar solo 1 item
          overflowButtonType={OverflowButtonType.none} // No mostrar botón de desbordamiento
          onRenderPersona={(props) => (
            <Persona {...props} size={PersonaSize.size24} onRenderPrimaryText={() => <></>}></Persona>
          )}
        />
      </span>
    </div>
  );
};
