import React from 'react';
import {
  Pivot,
  PivotItem,
  Text,
  IconButton,
  Icon,
  DefaultButton,
  ChoiceGroup,
  IChoiceGroupOption,
  Dropdown,
} from '@fluentui/react';
import ClubImageCircle from '../Common/ClubImageCircle';
import { grilla, ModeloGrilla, todosContraTodos, Torneo } from '../../system/SystemClient';
import { PartidoFixture } from '../Common/PartidoFixture';
import { genkey, PARTIDOS_EXTRA_ZONA_NAME } from '../../common/constants';
import { FechaFixture, ZonaFixture } from '../Common/ZonaFixture';
import { TorneoLlaves } from './TorneoLlaves';
import { InstanciaPorFase, TorneoInstanciaFixture } from './TorneoInstanciaFixture';

const FIXTURE = 'F';
const LLAVES = 'L';

const TorneoTabFixture = ({ torneo }: { torneo: Torneo }) => {
  const [modeView, setModeView] = React.useState<string>(FIXTURE);
  const [filterMatchDay, setFilterMatchDay] = React.useState<string>('');

  const possiblesMatchDaysByFase = React.useMemo(() => {
    const ret = torneo.fases.map((f) => {
      let fechas = f.zonas[0].partidos.map((partido) => partido.numeroFecha);
      fechas = fechas.filter(function (item, pos) {
        return fechas.indexOf(item) === pos;
      });

      return { faseId: f.id, fechas: fechas };
    });
    return ret;
  }, []);

  const instanciasPorFase: InstanciaPorFase[] = React.useMemo(() => {
    return torneo.fases.map((fase) => {
      let instancias: any[] = [];
      fase.modeloGrilla.forEach((modelo) => {
        const existe = instancias.find(
          (i) =>
            i.ronda === modelo.mtg_ronda &&
            i.descripcion === modelo.mtg_descripcion &&
            i.llave_principal === modelo.mtg_llave_principal
        );
        if (!existe) {
          instancias.push({
            ronda: modelo.mtg_ronda,
            descripcion: modelo.mtg_descripcion,
            llave_principal: modelo.mtg_llave_principal,
            partidos: [modelo.mtg_partido_numero],
          });
        } else {
          existe.partidos.push(modelo.mtg_partido_numero);
        }
      });

      return {
        fase: fase.nombre,
        instancias: instancias,
      };
    });
  }, []);

  return (
    <>
      <Pivot aria-label="" className="text-left">
        {torneo.fases.map((fase, iFase) => (
          <PivotItem headerText={fase.nombre} key={genkey()}>
            {grilla(fase) && (
              <div className="ms-Grid-row mt-2">
                <div className="ms-Grid-col ms-sm12">
                  <DefaultButton
                    onClick={() => setModeView(FIXTURE)}
                    disabled={modeView === FIXTURE}
                    iconProps={{ iconName: 'Calendar' }}
                  >
                    Fixture
                  </DefaultButton>
                  <DefaultButton
                    style={{ marginLeft: 5 }}
                    onClick={() => setModeView(LLAVES)}
                    disabled={modeView === LLAVES}
                    iconProps={{ iconName: 'Trophy2' }}
                  >
                    Llaves
                  </DefaultButton>
                </div>
              </div>
            )}

            {todosContraTodos(fase) && (
              <div className="ms-Grid-row mt-2">
                <div className="ms-Grid-col ms-sm12">
                  <DefaultButton
                    onClick={() => {
                      setFilterMatchDay('');
                    }}
                    style={{ marginLeft: 5, height: 50 }}
                    primary={filterMatchDay === ''}
                  >
                    Todas las fechas
                  </DefaultButton>
                  <div className="ms-hiddenSm" style={{ display: 'inline-block' }}>
                    {possiblesMatchDaysByFase
                      .find((f) => f.faseId === fase.id)
                      ?.fechas.map((fecha) => {
                        return (
                          <DefaultButton
                            onClick={() => setFilterMatchDay(String(fecha))}
                            primary={filterMatchDay === fecha}
                            style={{ marginLeft: 5, minWidth: 50, width: 50, minHeight: 50, height: 50 }}
                            key={genkey()}
                          >
                            {fecha}
                          </DefaultButton>
                        );
                      })}
                  </div>
                  <div
                    className="ms-hiddenMdUp"
                    style={{ display: 'inline-block', width: '50%', float: 'right' }}
                  >
                    <Dropdown
                      onChange={(e, opt) => {
                        setFilterMatchDay(String(opt!.key!));
                      }}
                      selectedKey={filterMatchDay}
                      placeholder="Fecha especifica"
                      options={
                        possiblesMatchDaysByFase
                          .find((f) => f.faseId === fase.id)
                          ?.fechas.map((fecha) => {
                            return { key: fecha, text: 'Fecha ' + fecha };
                          })!
                      }
                    ></Dropdown>
                  </div>
                </div>
              </div>
            )}

            {todosContraTodos(fase) ? (
              <div>
                {fase.zonas.map((zona, iZona) => {
                  return (
                    <div key={genkey()}>
                      {zona.partidos.length > 0 && (
                        <div className="mt-2">
                          {fase.zonas.filter((zona) => zona.nombre !== PARTIDOS_EXTRA_ZONA_NAME).length >
                            1 && <ZonaFixture zona={zona} />}
                          {filterMatchDay === '' &&
                            possiblesMatchDaysByFase
                              .find((fxf) => fxf.faseId === fase.id)!
                              .fechas.map((fecha) => {
                                return (
                                  <>
                                    {zona.partidos.filter((p) => p.numeroFecha === String(fecha)).length >
                                      0 && (
                                      <>
                                        <FechaFixture fecha={String(fecha)}></FechaFixture>
                                        {zona.partidos
                                          .filter((p) => p.numeroFecha === String(fecha))
                                          .map((partido, iPartido) => {
                                            return <PartidoFixture partido={partido} />;
                                          })}
                                      </>
                                    )}
                                  </>
                                );
                              })}
                          {filterMatchDay !== '' &&
                            zona.partidos
                              .filter((p) =>
                                filterMatchDay !== '' ? p.numeroFecha === filterMatchDay : true
                              )
                              .map((partido, iPartido) => {
                                return <PartidoFixture partido={partido} />;
                              })}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>
                {fase.zonas.map((zona, iZona) => {
                  return (
                    <div key={genkey()}>
                      {fase.zonas.filter((zona) => zona.nombre !== PARTIDOS_EXTRA_ZONA_NAME).length > 1 && (
                        <ZonaFixture zona={zona} />
                      )}
                      {zona.partidos.length > 0 && (
                        <div className="mt-2">
                          {modeView === FIXTURE ? (
                            <TorneoInstanciaFixture
                              zona={zona}
                              fase={fase}
                              instanciasPorFase={instanciasPorFase}
                            ></TorneoInstanciaFixture>
                          ) : (
                            <div>
                              <TorneoLlaves zona={zona} modeloGrilla={fase.modeloGrilla} />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </PivotItem>
        ))}
      </Pivot>
    </>
  );
};

export default TorneoTabFixture;
